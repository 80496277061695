<template>
    <v-card>
        <v-card-title class="align-start">
            <span class="font-weight-semibold">{{ $t("statistics") }}</span>
            <v-spacer></v-spacer>
            <v-btn icon small class="me-n3 mt-n2"> </v-btn>
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col
                    v-for="data in allCounts"
                    :key="data.title"
                    cols="6"
                    md="2"
                    class="d-flex align-center"
                >
                    <v-avatar
                        size="44"
                        :color="
                            resolveStatisticsIconVariation(data.title).color
                        "
                        rounded
                        class="elevation-1"
                    >
                        <v-icon dark color="white" size="30">
                            {{
                                resolveStatisticsIconVariation(data.title).icon
                            }}
                        </v-icon>
                    </v-avatar>
                    <div class="ms-3">
                        <p class="text-xs mb-0">
                            {{ data.title }}
                        </p>
                        <h3 class="text-xl font-weight-semibold">
                            {{ data.total }}
                        </h3>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    //prop all-counts
    props: {
        allCounts: { type: Array, default: () => [] },
    },

    methods: {
        resolveStatisticsIconVariation(title) {
            switch (title) {
                case this.$t("guardians"):
                    return {
                        icon: "mdi-account-group-outline",
                        color: "primary",
                    };
                case this.$t("schools"):
                    return {
                        icon: "mdi-school",
                        color: "info",
                    };
                case this.$t("drivers"):
                    return {
                        icon: "mdi-account-tie-hat-outline",
                        color: "success",
                    };
                case this.$t("students"):
                    return {
                        icon: "mdi-badge-account-outline",
                        color: "info",
                    };
                case this.$t("reservations"):
                    return {
                        icon: "mdi-poll",
                        color: "info",
                    };
                case this.$t("trips"):
                    return {
                        icon: "mdi-bus-clock",
                        color: "warning",
                    };
                case this.$t("routes"):
                    return {
                        icon: "mdi-road-variant",
                        color: "error",
                    };
                case this.$t("stops"):
                    return {
                        icon: "mdi-bus-stop",
                        color: "success",
                    };
                default:
                    return {
                        icon: "mdi-account",
                        color: "primary",
                    };
            }
        },
    },
};
</script>
